<template>
	<div class="animated fadeIn tender-page">
		<h4
			v-if="data.status === 0 || data.status === 5"
			class="tender-page__title"
		>
			Тендер <span v-if="data.name">{{ data.name }}</span>
			<span v-if="data.status === 0" class="text-primary">
				(Черновик)</span
			>
			<span v-else-if="data.status === 5" class="text-danger">
				(Отменен)</span
			>
		</h4>
		<div v-if="form.organizations.value" class="custom-fieldset">
			<h4>Организация - {{ form.organizations.value }}</h4>
		</div>
		<div v-if="data.status === 3" class="tender-status-card">
			<p class="tender-status-card__title">
				Подведение итогов
			</p>
			<div
				class="tender-status-card__icon-container tender-status-card__icon-container_warning"
			>
				<i class="tender-status-card__icon fa fa-clock-o fa-sm mr-3" />
			</div>
			<p class="tender-status-card__desc">
				Прием ставок и предложений остановлен. В настоящий момент
				определяется победитель.
			</p>
		</div>
		<div
			v-else-if="data.status === 4 && data.contractorProposal.winner"
			class="tender-status-card"
		>
			<p class="tender-status-card__title">
				Вы победитель
			</p>
			<div
				class="tender-status-card__icon-container tender-status-card__icon-container_success"
			>
				<i class="tender-status-card__icon fa fa-trophy fa-sm mr-3" />
			</div>
			<p class="tender-status-card__desc">
				Система определила, что ваше предложение самое лучшее.
			</p>
		</div>
		<div v-if="data.status !== 0 && data.status !== 5" class="tender-info">
			<div class="tender-info__body">
				<h1 class="tender-info__body-title">
					Тендер <span v-if="data.name">{{ data.name }}</span>
					<span
						v-if="data.status === 1"
						class="tender-info__body-title-status bg-primary"
						>Ожидает начала</span
					>
					<span
						v-else-if="data.status === 2"
						class="tender-info__body-title-status bg-success"
						>Прием предложений</span
					>
					<span
						v-else-if="data.status === 3"
						class="tender-info__body-title-status bg-warning"
						>Подведение итогов</span
					>
					<span
						v-else-if="data.status === 4"
						class="tender-info__body-title-status bg-danger"
						>Завершен</span
					>
					<span
						v-else-if="data.status === 6"
						class="tender-info__body-title-status bg-secondary"
						>Утвержден</span
					>
					<span
						v-else-if="data.status === 7"
						class="tender-info__body-title-status bg-warning"
						>Ожидает генерации рейсов</span
					>
				</h1>
				<b-row>
					<b-col md="4">
						<div class="tender-info__nds">
							<p class="tender-info__nds-title">
								Данный тендер имеет ценообразование —
								<b v-if="data.VAT"> с НДС</b>
								<b v-else> без НДС</b>
							</p>
							<b-form-group class="tender-info__nds-checkbox">
								<b-form-checkbox
									v-model="data.alternativeAmounts"
									name="alternative-amounts"
								>
									Отображать альтернативные суммы
								</b-form-checkbox>
							</b-form-group>
						</div>
						<div class="tender-info__item">
							<p class="tender-info__item-title">
								Стартовая стоимость тендера
							</p>
							<p class="tender-info__item-desc">
								{{ numberRUFormat(data.totalPrice) }} ₽
								<span v-if="data.VAT">
									(С НДС)
								</span>
								<span v-else>
									(Без НДС)
								</span>
								<span
									v-if="data.alternativeAmounts"
									class="tender-info__item-desc-note"
								>
									{{
										alternativeAmountValue(data.totalPrice)
									}}
								</span>
							</p>
						</div>
						<div class="tender-info__item">
							<p class="tender-info__item-title">
								Стоимость с вашими ставками/предложениями
							</p>
							<p class="tender-info__item-desc">
								<span v-if="data.contractorProposal.sum"
									>{{
										numberRUFormat(
											data.contractorProposal.sum
										)
									}}
									₽
									<span
										v-if="data.alternativeAmounts"
										class="tender-info__item-desc-note"
									>
										{{
											alternativeAmountValue(
												data.contractorProposal.sum
											)
										}}
									</span>
								</span>
								<span v-else
									>Отсутствуют ставки или предложения</span
								>
							</p>
						</div>
					</b-col>
					<b-col md="4">
						<div class="tender-info__item">
							<p
								v-if="data.status === 1"
								class="tender-info__item-title"
							>
								До начала осталось
							</p>
							<p v-else class="tender-info__item-title">
								До окончания осталось
							</p>
							<p
								v-if="
									data.status === 3 ||
										data.status === 4 ||
										data.status === 6 ||
										data.status === 7
								"
								class="tender-info__item-desc"
							>
								Тендер завершен
							</p>
							<p v-else class="tender-info__item-desc">
								{{ data.countdown }}
							</p>
						</div>
						<div class="tender-info__item">
							<p class="tender-info__item-title">
								Дата начала
							</p>
							<p class="tender-info__item-desc">
								<i class="fa fa-clock-o fa-sm mr-3" />{{
									formatDatetime(data.startDate)
								}}
							</p>
						</div>
						<div class="tender-info__item">
							<p class="tender-info__item-title">
								Дата окончания
							</p>
							<p class="tender-info__item-desc">
								<i class="fa fa-clock-o fa-sm mr-3" />{{
									formatDatetime(data.endDate)
								}}
							</p>
						</div>
					</b-col>
					<b-col md="4">
						<div class="tender-info__item">
							<p class="tender-info__item-title">
								Всего маршрутов
							</p>
							<p class="tender-info__item-desc">
								{{ form.routeWays.length }}
							</p>
						</div>

            <div class="tender-info__item">
              <p class="tender-info__item-title">
                Стоимость заезда в доп. точку
              </p>
              <p class="tender-info__item-desc">
                {{ numberRUFormat(data.additionalWayPointPrice) }} ₽
                <span v-if="data.VAT">
                  (С НДС)
                </span>
                <span v-else>
                  (Без НДС)
                </span>
                <span
                  v-if="data.alternativeAmounts"
                  class="tender-info__item-desc-note"
                >
                  {{ alternativeAmountValue(data.additionalWayPointPrice) }}
                </span>
              </p>
            </div>

						<div
							v-if="data.contractorProposal.id"
							class="tender-info__item"
						>
							<p class="tender-info__item-title">
								Итоговое состояние ваших ставок/предложений
							</p>
							<p class="tender-info__item-desc">
								<b>
									<span
										v-if="data.contractorProposal.active"
										class="text-success"
										>Ваши ставки/предложения лидируют</span
									>
									<span
										v-else-if="
											data.contractorProposalDifference
										"
										class="text-danger"
										>Ваши ставки/предложения уступают лидеру
										на
										{{ data.contractorProposalDifference }}
										₽</span
									>
									<span v-else
										>Ваши ставки/предложения идетичны
										лидирующей ставке</span
									>
								</b>
							</p>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>
		<div class="custom-fieldset">
			<tender-flight-schedule
				v-if="data.status != 6"
				:key="data.routeWay"
				:route-ways="data.routeWays"
			/>
		</div>
		<div
			v-for="(routeWay, index) in data.routeWays"
			:key="`route-way-${index}`"
			class="tender-route-main-info"
		>
			<div class="tender-route-main-info__header">
				<b-row>
					<b-col md="4">
						<p class="tender-route-main-info__title">
							Маршрут №{{ index + 1 }}
						</p>
						<ol
							v-if="routeWay.points.length > 0"
							class="tender-route-main-info__route"
						>
							<li class="tender-route-main-info__route-first">
								<i
									class="fa fa-map-marker fa-lg mr-2 text-danger"
								/>{{ routeWay.points[0].department.name }}
							</li>
							<li class="tender-route-main-info__route-last">
								<i
									class="fa fa-map-marker fa-lg mr-2 text-danger"
								/>{{
									routeWay.points[routeWay.points.length - 1]
										.department.name
								}}
							</li>
						</ol>
					</b-col>
					<b-col md="4">
						<p class="tender-route-main-info__title">
							Количество рейсов
						</p>
						<p class="tender-route-main-info__desc">
							{{ routeWay.routeCount }}
						</p>
					</b-col>
					<b-col md="4">
						<p class="tender-route-main-info__title">
							Стартовая стоимость маршрута
						</p>
						<p class="tender-route-main-info__desc">
							{{ numberRUFormat(routeWay.templatePrice) }} ₽
							<span
								v-if="data.alternativeAmounts"
								class="tender-info__item-desc-note"
							>
								{{
									alternativeAmountValue(
										routeWay.templatePrice
									)
								}}
							</span>
						</p>
					</b-col>
				</b-row>
			</div>
			<div class="tender-route-main-info__body">
				<div
					:class="{
						disabled: data.status !== 2 || routeWay.switcher,
					}"
					class="tender-route-main-info__body-bid"
				>
					<b-row>
						<b-col md="4">
							<p class="tender-route-main-info__title">
								Лидирующая ставка за 1 рейс
							</p>
							<p class="tender-route-main-info__desc">
								<span v-if="routeWay.smallestBid"
									>{{ numberRUFormat(routeWay.smallestBid) }}
									₽
									<span
										v-if="data.alternativeAmounts"
										class="tender-info__item-desc-note"
									>
										{{
											alternativeAmountValue(
												routeWay.smallestBid
											)
										}}
									</span>
								</span>
								<span v-else>Отсутствует</span>
							</p>
							<p class="tender-route-main-info__title">
								Ваша последняя ставка за 1 рейс
							</p>
							<p class="tender-route-main-info__desc">
								<span v-if="routeWay.smallestContractorBid"
									>{{
										numberRUFormat(
											routeWay.smallestContractorBid
										)
									}}
									₽
									<span
										v-if="data.alternativeAmounts"
										class="tender-info__item-desc-note"
									>
										{{
											alternativeAmountValue(
												routeWay.smallestContractorBid
											)
										}}
									</span>
								</span>
								<span v-else>Отсутствует</span>
							</p>
						</b-col>
						<b-col md="4">
							<div class="tender-route-main-info__bet">
								<div class="tender-route-main-info__bet-field">
									<button
										:disabled="
											data.status !== 2 ||
												routeWay.switcher
										"
										class="tender-route-main-info__bet-field-minus"
										type="button"
										@click="nextBet(index)"
									>
										-
									</button>
									<div
										class="tender-route-main-info__bet-field-value"
									>
										{{ form.routeWays[index].newBet }}
									</div>
									<button
										:disabled="
											data.status !== 2 ||
												routeWay.switcher
										"
										class="tender-route-main-info__bet-field-plus"
										type="button"
										@click="prevBet(index)"
									>
										+
									</button>
								</div>
								<p class="tender-route-main-info__bet-desc">
									Введите сумму за один рейс
									<span v-if="data.VAT"> с НДС</span>
									<span v-else> без НДС</span>.
									<span
										v-if="data.alternativeAmounts"
										class="tender-route-main-info__bet-desc-note"
									>
										{{
											alternativeAmountValue(
												routeWay.routePrice
											)
										}}
									</span>
								</p>
								<b-button
									:disabled="
										data.status !== 2 ||
											routeWay.switcher ||
											form.routeWays[index].newBet ===
												routeWay.smallestBid ||
											loading
									"
									class="tender-route-main-info__bet-button"
									variant="success"
									size="lg"
									:class="{ loading: loading }"
									@click="onTenderBid(index)"
								>
									Сделать ставку
								</b-button>
							</div>
						</b-col>
						<!-- <b-col md="4">
              <dimensions-view
                v-model="routeWay.dimensions"
                :price="routeWay.routePrice"
              />
            </b-col> -->
						<b-col md="4">
							<p class="tender-route-main-info__title">
								Стартовая стоимость одного рейса
							</p>
							<p class="tender-route-main-info__desc">
								{{ numberRUFormat(routeWay.routePrice) }} ₽
								<span
									v-if="data.alternativeAmounts"
									class="tender-info__item-desc-note"
								>
									{{
										alternativeAmountValue(
											routeWay.routePrice
										)
									}}
								</span>
							</p>
							<p
								v-if="routeWay.stepRate"
								class="tender-route-main-info__title"
							>
								Шаг ставки
							</p>
							<p
								v-if="routeWay.stepRate"
								class="tender-route-main-info__desc"
							>
								{{ numberRUFormat(routeWay.stepRate) }} ₽
							</p>
						</b-col>
					</b-row>
				</div>
				<div
					v-if="data.enableOffers"
					class="tender-route-main-info__switcher-container"
				>
					<label class="tender-route-main-info__switcher">
						<input
							v-model="routeWay.switcher"
							:disabled="data.status !== 2"
							type="checkbox"
							class="tender-route-main-info__switcher-input"
						/>
						<span class="tender-route-main-info__switcher-display">
							<span v-if="routeWay.switcher"
								><i
									class="tender-route-main-info__switcher-display-icon fa fa-arrow-up"
								/>Участвовать ставкой</span
							>
							<span v-else
								><i
									class="tender-route-main-info__switcher-display-icon fa fa-arrow-down"
								/>Участвовать встречным предложением</span
							>
						</span>
					</label>
				</div>
				<div
					v-if="data.enableOffers"
					:class="{
						disabled: data.status !== 2 || !routeWay.switcher,
					}"
					class="tender-route-main-info__body-offer"
				>
					<b-row>
						<b-col md="4">
							<p class="tender-route-main-info__title">
								Ваше предложение за 1 рейс
							</p>
							<p class="tender-route-main-info__desc">
								<span v-if="routeWay.contractorOffer.sum"
									>{{
										numberRUFormat(
											routeWay.contractorOffer.sum
										)
									}}
									₽
									<span
										v-if="data.alternativeAmounts"
										class="tender-info__item-desc-note"
									>
										{{
											alternativeAmountValue(
												routeWay.contractorOffer.sum
											)
										}}
									</span>
								</span>
								<span v-else>Отсутствует</span>
							</p>
						</b-col>
						<b-col md="4">
							<div class="tender-route-main-info__offer">
								<b-form-group
									class="tender-route-main-info__offer-field"
								>
									<b-form-input
										v-model="form.routeWays[index].newOffer"
										:disabled="
											data.status !== 2 ||
												!routeWay.switcher
										"
										class="tender-route-main-info__offer-input"
										type="number"
										autocomplete="false"
										@wheel="$event.target.blur()"
									/>
								</b-form-group>
								<p class="tender-route-main-info__offer-desc">
									Введите сумму за один рейс
									<span v-if="data.VAT"> с НДС</span>
									<span v-else> без НДС</span>.
									<span
										v-if="data.alternativeAmounts"
										class="tender-route-main-info__offer-desc-note"
									>
										{{
											alternativeAmountValue(
												form.routeWays[index].newOffer
											)
										}}
									</span>
								</p>
								<b-button
									:disabled="
										data.status !== 2 ||
											!routeWay.switcher ||
											loading
									"
									class="tender-route-main-info__offer-button"
									variant="warning"
									size="lg"
									:class="{ loading: loading }"
									@click="confirmTenderOffer(index)"
								>
									Сделать предложение
								</b-button>
							</div>
						</b-col>
						<b-col md="4">
							<b-button
								v-if="routeWay.contractorOffer.sum"
								:disabled="
									data.status !== 2 ||
										!routeWay.switcher ||
										loading
								"
								variant="danger"
								size="sm"
								:class="{ loading: loading }"
								@click="onTenderDeleteOffer(index)"
							>
								Отменить предложение
							</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
			<div class="tender-route-main-info__footer">
				<label class="tender-route-main-info__more">
					<input
						v-model="routeWay.more"
						type="checkbox"
						class="tender-route-main-info__more-input"
					/>
					<span class="tender-route-main-info__more-display"
						>Подробности о маршруте
						<i
							class="tender-route-main-info__more-display-icon fa fa-caret-down"
					/></span>
				</label>
			</div>
			<div v-if="routeWay.more" class="tender-route-additional-info">
				<div class="tender-route-additional-info__body">
					<h5 class="tender-route-additional-info__main-title">
						Маршрут
					</h5>
					<b-row>
						<b-col md="2">
							<p class="tender-route-additional-info__title">
								Пункты назначения
							</p>
							<ol
								v-if="routeWay.points.length > 0"
								class="tender-route-additional-info__destinations"
							>
								<li
									v-for="(point,
									indexPoint) in routeWay.points"
									:key="
										`${indexPoint}-route-way-point-${point.id}`
									"
									class="tender-route-additional-info__destinations-item"
								>
									<i
										class="tender-route-additional-info__destinations-item-icon fa fa-map-marker fa-lg mr-2 text-danger"
									/>{{
										point.department.mainWarehouse.address
									}}
									<span
										v-if="indexPoint === 0"
										class="tender-route-additional-info__destinations-item-arrival"
									/>
								</li>
							</ol>
						</b-col>
						<b-col md="2">
							<p class="tender-route-additional-info__title">
								Протяженность маршрута
							</p>
							<p class="tender-route-additional-info__desc">
								{{ numberRUFormat(routeWay.fullDistance) }} км
							</p>
						</b-col>
						<b-col md="2">
							<p class="tender-route-additional-info__title">
								Ориентировочное время
							</p>
							<p class="tender-route-additional-info__desc">
								{{ numberRUFormat(routeWay.timeSummer) }}
							</p>
							<small><strong>*</strong> Точное время доставки будет указано в <br> транспортной накладной после отправки.</small>
						</b-col>
						<b-col md="2">
							<p class="tender-route-additional-info__title">
								Код маршрута
							</p>
							<p class="tender-route-additional-info__desc">
								{{ routeWay.playedRouteWay }}
							</p>
						</b-col>
						<b-col md="2" v-if="routeWay.routeType">
							<p class="tender-route-additional-info__title">
								Вид маршрута
							</p>
							<p class="tender-route-additional-info__desc">
								{{ displayRouteType(routeWay.routeType) }}
							</p>
						</b-col>
						<b-col md="2">
							<p class="tender-route-additional-info__title">
								Тип перевозки
							</p>
							<p class="tender-route-additional-info__desc">
								{{ routeWay.transportationName }}
							</p>
						</b-col>
						<b-col md="2">
							<p class="tender-route-additional-info__title">
								Вид перевозки
							</p>
							<p class="tender-route-additional-info__desc">
								{{ routeWay.transportationType }}
							</p>
						</b-col>
					</b-row>
					<h5 class="tender-route-additional-info__main-title">
						Требования к транспорту
					</h5>
					<b-row>
						<b-col md="4">
							<p class="tender-route-additional-info__title">
								Габариты
							</p>
							<p class="tender-route-additional-info__desc">
								<dimensions-view
									v-if="data.status !== 6"
									v-model="routeWay.dimensions"
									:price="routeWay.routePrice"
								/>
								<dimensions-view
									v-else
									v-model="routeWay.dimensions"
									:price="routeWay.winRouteSumm"
								/>
							</p>
						</b-col>
						<b-col md="4">
							<p class="tender-route-additional-info__title">
								Погрузка/Выгрузка
								<i
									v-if="routeWay.loadingUnloading.length > 1"
									class="tender-route-additional-info__title-note"
									>(один из вариантов)</i
								>
							</p>
							<ul class="tender-route-additional-info__list">
								<li
									v-for="item in routeWay.loadingUnloading"
									:key="`loading-unloading-${item.id}`"
									class="tender-route-additional-info__list-item"
								>
									{{ item.name }}
								</li>
							</ul>
						</b-col>
						<b-col md="4">
							<p class="tender-route-additional-info__title">
								Тип кузова
								<i
									v-if="routeWay.bodyType.length > 1"
									class="tender-route-additional-info__title-note"
									>(один из вариантов)</i
								>
							</p>
							<ul class="tender-route-additional-info__list">
								<li
									v-for="item in routeWay.bodyType"
									:key="`loading-unloading-${item.id}`"
									class="tender-route-additional-info__list-item"
								>
									{{ item.name }}
								</li>
							</ul>
						</b-col>
					</b-row>
					<h5 class="tender-route-additional-info__main-title">
						Расписание
					</h5>
					<b-row>
						<b-col md="4">
							<p class="tender-route-additional-info__title">
								Дата начала маршрута
							</p>
							<p class="tender-route-additional-info__desc">
								{{ formatDate(routeWay.startDate) }}
							</p>
						</b-col>
						<b-col md="4">
							<p class="tender-route-additional-info__title">
								Дата окончания маршрута
							</p>
							<p class="tender-route-additional-info__desc">
								{{ formatDate(routeWay.stopDate) }}
							</p>
						</b-col>
						<b-col md="4">
							<p class="tender-route-additional-info__title">
								Количество недель
							</p>
							<p class="tender-route-additional-info__desc">
								{{ routeWay.weekCount }}
							</p>
						</b-col>
					</b-row>
					<h6 class="tender-route-additional-info__subtitle">
						Расписание на неделю
					</h6>
					<div class="route-way-week">
						<div class="route-way-week__head">
							<div
								:class="{
									active:
										routeWay.weekTemplate.monday.length > 0,
								}"
								class="route-way-week__head-item"
							>
								ПН
							</div>
							<div
								:class="{
									active:
										routeWay.weekTemplate.tuesday.length >
										0,
								}"
								class="route-way-week__head-item"
							>
								ВТ
							</div>
							<div
								:class="{
									active:
										routeWay.weekTemplate.wednesday.length >
										0,
								}"
								class="route-way-week__head-item"
							>
								СР
							</div>
							<div
								:class="{
									active:
										routeWay.weekTemplate.thursday.length >
										0,
								}"
								class="route-way-week__head-item"
							>
								ЧТ
							</div>
							<div
								:class="{
									active:
										routeWay.weekTemplate.friday.length > 0,
								}"
								class="route-way-week__head-item"
							>
								ПТ
							</div>
							<div
								:class="{
									active:
										routeWay.weekTemplate.saturday.length >
										0,
								}"
								class="route-way-week__head-item"
							>
								СБ
							</div>
							<div
								:class="{
									active:
										routeWay.weekTemplate.sunday.length > 0,
								}"
								class="route-way-week__head-item"
							>
								ВС
							</div>
						</div>
						<div class="route-way-week__body">
							<div class="route-way-week__body-weekday">
								<label
									v-for="(item, weekItemIndex) in routeWay
										.weekTemplate.monday"
									:key="
										`route-way-week-item-${weekItemIndex}`
									"
									class="route-way-week__body-item"
								>
									<b-form-input
										v-model="item.time"
										autofocus
										type="time"
										placeholder="00:00"
										class="route-way-week__body-time"
										disabled
									/>
								</label>
							</div>
							<div class="route-way-week__body-weekday">
								<label
									v-for="(item, weekItemIndex) in routeWay
										.weekTemplate.tuesday"
									:key="
										`route-way-week-item-${weekItemIndex}`
									"
									class="route-way-week__body-item"
								>
									<b-form-input
										v-model="item.time"
										autofocus
										type="time"
										placeholder="00:00"
										class="route-way-week__body-time"
										disabled
									/>
								</label>
							</div>
							<div class="route-way-week__body-weekday">
								<label
									v-for="(item, weekItemIndex) in routeWay
										.weekTemplate.wednesday"
									:key="
										`route-way-week-item-${weekItemIndex}`
									"
									class="route-way-week__body-item"
								>
									<b-form-input
										v-model="item.time"
										autofocus
										type="time"
										placeholder="00:00"
										class="route-way-week__body-time"
										disabled
									/>
								</label>
							</div>
							<div class="route-way-week__body-weekday">
								<label
									v-for="(item, weekItemIndex) in routeWay
										.weekTemplate.thursday"
									:key="
										`route-way-week-item-${weekItemIndex}`
									"
									class="route-way-week__body-item"
								>
									<b-form-input
										v-model="item.time"
										autofocus
										type="time"
										placeholder="00:00"
										class="route-way-week__body-time"
										disabled
									/>
								</label>
							</div>
							<div class="route-way-week__body-weekday">
								<label
									v-for="(item, weekItemIndex) in routeWay
										.weekTemplate.friday"
									:key="
										`route-way-week-item-${weekItemIndex}`
									"
									class="route-way-week__body-item"
								>
									<b-form-input
										v-model="item.time"
										autofocus
										type="time"
										placeholder="00:00"
										class="route-way-week__body-time"
										disabled
									/>
								</label>
							</div>
							<div class="route-way-week__body-weekday">
								<label
									v-for="(item, weekItemIndex) in routeWay
										.weekTemplate.saturday"
									:key="
										`route-way-week-item-${weekItemIndex}`
									"
									class="route-way-week__body-item"
								>
									<b-form-input
										v-model="item.time"
										autofocus
										type="time"
										placeholder="00:00"
										class="route-way-week__body-time"
										disabled
									/>
								</label>
							</div>
							<div class="route-way-week__body-weekday">
								<label
									v-for="(item, weekItemIndex) in routeWay
										.weekTemplate.sunday"
									:key="
										`route-way-week-item-${weekItemIndex}`
									"
									class="route-way-week__body-item"
								>
									<b-form-input
										v-model="item.time"
										autofocus
										type="time"
										placeholder="00:00"
										class="route-way-week__body-time"
										disabled
									/>
								</label>
							</div>
						</div>
					</div>
					<div v-if="form.routeWays[index].note.value && form.routeWays[index].note.value !== undefined">
          <div class="mt-3 mb-4">
          <b-row>
            <b-col md="4">
              <p class="main-info__item-title">Комментарий к маршруту тендера</p>
              <p class="main-info__item-desc">
                {{form.routeWays[index].note.value}}
              </p>
            </b-col>
          </b-row>								
        </div>
        </div>
				</div>
			</div>
		</div>
		<div class="fixed-buttons">
			<b-button
				type="button"
				variant="secondary"
				class="mr-2"
				:class="{ loading: loading }"
				@click="$router.go(-1)"
			>
				Назад
			</b-button>
		</div>
	</div>
</template>

<script>
import DimensionsView from "../../components/DimensionsView.vue";
import TenderFlightSchedule from "../../components/TenderFlightSchedule.vue";
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import notifications from "../../components/mixins/notifications";
import {
	contractorsTenderRead,
	contractorsTendersRouteTemplateBids,
	contractorsTendersRouteTemplateOffer,
	contractorsTendersRouteTemplateEditOffer,
	contractorsTendersRouteTemplateDeleteOffer,
	contractorsTenderAcceptWin,
	contractorsTenderDeclineWin,
	enterToTenderPage,
	tenderBet,
} from "../../services/api";
import {
	valueToValueWithVAT,
	valueToValueWithoutVAT,
	numberToRUFormat,
} from "@/components/mixins/helpers";
import moment from "moment";
import tz from "moment-timezone";

Vue.use(ClientTable);

const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_AUCTION_API_URL);
const urlArray = window.location.pathname.split("/");
const tendersId = urlArray[urlArray.length - 1].substr(2);

socket.onopen = () => {
	socket.send(
		JSON.stringify({
			event: "authorize",
			data: {
				jwt: localStorage.token,
				tenderId: Number(tendersId),
			},
		})
	);
};

export default {
	name: "ContractorTendersView",
	components: {
		ClientTable,
		Event,
		TenderFlightSchedule,
		DimensionsView,
	},
	mixins: [notifications],
	props: ["contractorId", "tendersId"],
	data() {
		return {
			note: {
				id: null,
				value: null,
			},
			data: {
				status: null,
        additionalWayPointPrice: null,
				enableOffers: false,
				VAT: true,
				alternativeAmounts: false,
				countdown: null,
				winner: {},
				name: null,
				totalPrice: null,
				activeProposalSum: null,
				contractorProposal: {},
				contractorProposalDifference: null,
				startDate: null,
				endDate: null,
				activeOffer: {},
				routeWays: [
					{
						id: null,
						points: [],
						routeNumber: null,
						routeWayCode: null,
						routeCount: null,
						templatePrice: null,
						routePrice: null,
						routeType: "oneWay",
						stepRate: null,
						smallestBid: null,
						smallestContractorBid: null,
						switcher: false,
						contractorOffer: {},
						more: false,
						fullDistance: null,
						timeSummer: null,
						playedRouteWay: null,
						transportationType: null,
						transportationName: null,
						size: null,
						loadingUnloading: [],
						bodyType: [],
						startDate: null,
						stopDate: null,
						weekCount: null,
						department: [],
						weekTemplate: {
							monday: [],
							tuesday: [],
							wednesday: [],
							thursday: [],
							friday: [],
							saturday: [],
							sunday: [],
						},
					},
				],
			},
			form: {
				routeWays: [
					{
						note: {
							id: null,
							value: null,
            			},
						newBet: null,
						newOffer: null,
					},
				],
				organizations: {
					value: null,
					options: [],
				},
			},
			offers: {
				columns: ["contractor.name", "createdOn", "summ", "actions"],
				options: {
					headings: {
						"contractor.name": "Название компании",
						createdOn: "Дата/время подачи предложения",
						summ: "Предложенная цена",
						actions: "",
					},
					sortable: ["contractor.name", "createdOn", "summ"],
					filterable: ["contractor.name"],
					sortIcon: {
						base: "fa",
						up: "fa-sort-asc",
						down: "fa-sort-desc",
						is: "fa-sort",
					},
					pagination: {
						chunk: 10,
						edge: true,
						nav: "fixed",
					},
					perPage: 5,
					perPageValues: [5, 10, 25],
					texts: {
						count:
							"Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись",
						first: "Первая",
						last: "Последняя",
						filter: "Фильтр:",
						filterPlaceholder: "Поисковый запрос",
						limit: "Записи:",
						page: "Страница:",
						noResults: "Нет совпадающих записей",
						filterBy: "Фильтр по {column}",
						loading: "Загрузка...",
						defaultOption: "Выбор {column}",
						columns: "Столбцы",
					},
				},
				items: [],
				theme: "bootstrap4",
			},
			pickerOptions: {
				firstDayOfWeek: 1,
			},
			startTenderModal: {
				show: false,
				method: {
					options: [
						{ value: 0, text: "Запустить сейчас" },
						{ value: 1, text: "Запустить как отложенный" },
					],
					value: 0,
				},
				startDate: "",
				stopDate: "",
			},
			userPrice: 0,
			storePrice: 0,
			tenderRouteWay: "",
			tenderStartPrice: 0,
			loading: false,
			useVuex: false,
			template: "default",
		};
	},
	computed: {
		startTenderDisabled() {
			if (this.startTenderModal.method.value) {
				return !(
					this.startTenderModal.startDate &&
					this.startTenderModal.stopDate
				);
			} else {
				return !this.startTenderModal.stopDate;
			}
		},
		displayRouteType() {
			return function(routeType) {
			if (routeType === 'oneWay') {
				return 'В один конец';
			} else if (routeType === 'circular') {
				return 'Круговой';
			}
			};
		}
	},
	sockets: {
		connect: function() {
			console.log("socket connected");
		},
		customEmit: function(data) {
			console.log(
				'this method was fired by the socket server. eg: io.emit("customEmit", data)'
			);
		},
	},
	watch: {
		$route() {
			this.contractorsTenderRead();
		},
	},
	created() {
		socket.onmessage = (message) => {
			this.loading = true;
			try {
				const data = JSON.parse(message.data);

				if (data.status) {
					this.data.status = data.status;
				}

				if (data.enableOffers !== undefined) {
					this.data.enableOffers = data.enableOffers;
				}

				if (data.winner) {
					this.data.winner = data.winner;
				}
				if (data.name) {
					this.data.name = data.name;
				}

        if (data.additionalWayPointPrice) {
          this.data.additionalWayPointPrice = data.additionalWayPointPrice;
        }

				if (data.withNDS !== undefined) {
					this.data.VAT = data.withNDS;
				}
				if (data.summ) {
					this.data.totalPrice = data.summ;
				}
				if (data.activeProposal?.sum) {
					this.data.activeProposalSum = data.activeProposal.sum;
				}
				if (data.contractorProposal) {
					this.data.contractorProposal = data.contractorProposal;
					this.data.contractorProposalDifference =
						this.data.contractorProposal.sum -
						this.data.activeProposalSum;
				}
				if (data.startDate) {
					this.data.startDate = data.startDate;
				}
				if (data.factEndDate) {
					this.data.endDate = data.factEndDate;
				} else if (data.stopDate) {
					if (this.data.endDate !== data.stopDate) {
						this.data.endDate = data.stopDate;

						let eventTime;
						if (data.status === 1) {
							eventTime = moment(data.startDate).format("x");
						} else {
							eventTime = moment(data.stopDate).format("x");
						}
						const currentTime = moment().format("x");
						const diffTime = eventTime - currentTime;
						let duration = moment.duration(diffTime);
						const interval = 1000;

						setInterval(() => {
							duration = moment.duration(
								duration - interval,
								"milliseconds"
							);
							if (duration.days() === 0) {
								this.data.countdown =
									duration.hours() +
									" ч. " +
									duration.minutes() +
									" м. " +
									duration.seconds() +
									" сек.";
							} else {
								this.data.countdown =
									duration.days() +
									" д. " +
									duration.hours() +
									" ч. " +
									duration.minutes() +
									" м. " +
									duration.seconds() +
									" сек.";
							}
						}, interval);
					}
				}
				if (data.routeTemplates.length > 0) {
					data.routeTemplates.forEach((routeTemplate, index) => {
						if (index) {
							this.addRouteWay(index);
						}
						Vue.set(
						this.form.routeWays[index],
						"note",
						{id: null,
						value: null}
					)
						this.data.routeWays[index].routeNumber = index;
						if (routeTemplate.id) {
							this.data.routeWays[index].id = routeTemplate.id;
						}
						if (routeTemplate.routeWay) {
							this.data.routeWays[index].playedRouteWay =
								routeTemplate.routeWay.code;
							this.data.routeWays[
								index
							].points = routeTemplate.routeWay.routeWayPoints.sort(
								(a, b) => a.rowNumber - b.rowNumber
							);
							this.data.routeWays[
								index
							].fullDistance = routeTemplate.routeWay.routeWayPoints
								.reduce(function(accumulator, currentValue) {
									return (
										accumulator +
										Number(currentValue.distance)
									);
								}, 0)
								.toFixed(2);
							this.data.routeWays[
								index
							].timeSummer = routeTemplate.routeWay.routeWayPoints
								.reduce(function(accumulator, currentValue) {
									return (
										accumulator +
										Number(currentValue.timeSummer)
									);
								}, 0)
								.toFixed(2);
							const departments = [];
							routeTemplate.routeWay.routeWayPoints.forEach(
								(point) => {
									departments.push(point.department.name);
								}
							);
							this.data.routeWays[index].department = departments;
						}
						if (routeTemplate.transportation) {
							this.data.routeWays[index].transportationName =
								routeTemplate.transportation.name;
						}
						if (routeTemplate.routeType) {
							this.data.routeWays[index].routeType = routeTemplate.routeType;
						} else {
							this.data.routeWays[index].routeType = 'oneWay';
						}
						if (routeTemplate.routeWay.transportationType) {
							this.data.routeWays[index].transportationType =
								routeTemplate.routeWay.transportationType.name;
						}
						if (routeTemplate.routeWay.code) {
							this.data.routeWays[index].routeWayCode =
								routeTemplate.routeWay.code;
						}
						if (routeTemplate.routeSumm) {
							this.data.routeWays[index].routePrice = Number(
								routeTemplate.routeSumm
							);
						}
						if (routeTemplate.winRouteSumm) {
							this.data.routeWays[index].winRouteSumm = Number(
								routeTemplate.winRouteSumm
							);
						}
						if (routeTemplate.templateSumm) {
							this.data.routeWays[index].templatePrice =
								routeTemplate.templateSumm;
						}
						if (routeTemplate.step) {
							this.data.routeWays[index].stepRate = Number(
								routeTemplate.step
							);
						}
						if (routeTemplate.smallestBid) {
							this.data.routeWays[index].smallestBid =
								routeTemplate.smallestBid.bet;
							this.form.routeWays[index].newBet =
								routeTemplate.smallestBid.bet;
						} else {
							this.form.routeWays[index].newBet = Number(
								routeTemplate.routeSumm
							);
						}
						if (routeTemplate.smallestContractorBid) {
							this.data.routeWays[index].switcher = false;
							this.data.routeWays[
								index
							].smallestContractorBid = Number(
								routeTemplate.smallestContractorBid
							);
						}
						this.data.routeWays[index].contractorOffer = {};
						if (routeTemplate.contractorOffer) {
							this.data.routeWays[index].switcher = true;
							this.data.routeWays[index].contractorOffer =
								routeTemplate.contractorOffer;
							this.form.routeWays[index].newOffer =
								routeTemplate.contractorOffer.sum;
						} else {
							this.form.routeWays[index].newOffer = Number(
								routeTemplate.routeSumm
							);
						}
						this.data.routeWays[index].more = false;
						if (routeTemplate.periodStart) {
							const clientTimezone = Intl.DateTimeFormat().resolvedOptions()
								.timeZone;
							this.data.routeWays[index].startDate = moment(
								routeTemplate.periodStart,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format();
						}
						if (routeTemplate.periodStop) {
							const clientTimezone = Intl.DateTimeFormat().resolvedOptions()
								.timeZone;
							this.data.routeWays[index].stopDate = moment(
								routeTemplate.periodStop,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format();
						}
						if (routeTemplate.routeDateTemplates.length > 0) {
							this.data.routeWays[index].weekTemplate = {
								monday: [],
								tuesday: [],
								wednesday: [],
								thursday: [],
								friday: [],
								saturday: [],
								sunday: [],
							};
							routeTemplate.routeDateTemplates.forEach(
								(routeDateTemplate) => {
									const routeDateTemplateDateParams = routeDateTemplate.dateTemplate.split(
										" "
									);
									this.data.routeWays[index].weekTemplate[
										routeDateTemplateDateParams[0]
									].push({
										weekday: routeDateTemplateDateParams[0],
										time:
											routeDateTemplateDateParams[
												routeDateTemplateDateParams.length -
													1
											],
									});
								}
							);
						}
						if (routeTemplate.loadingTypes.length > 0) {
							this.data.routeWays[index].loadingUnloading =
								routeTemplate.loadingTypes;
						}
						if (routeTemplate.bodyTypes) {
							this.data.routeWays[index].bodyType =
								routeTemplate.bodyTypes;
						}
						this.data.routeWays[index].dimensions = {
							id: routeTemplate.routeWay.id,
							dimension: routeTemplate.dimension,
							optionalDimensions:
								routeTemplate.optionalDimensions,
						};
						this.tenderComputing(index);
					});
				}
			} catch (e) {
				console.log(e);
			}
			this.loading = false;
		};
		socket.onclose = (event) => {
			console.log("onclose");
			console.log(event);
		};
		socket.onerror = (error) => {
			console.log("onerror");
			console.log("Ошибка " + error.message);
		};
	},
	mounted() {
		this.contractorsTenderRead();
	},
	methods: {
		formatDatetime(date) {
			return date ? moment(date).format("DD.MM.YYYY HH:mm") : "";
		},
		formatDate(date) {
			return moment(date, "YYYY-MM-DDTHH:mm").format("DD.MM.YYYY");
		},
		alternativeAmountValue(value) {
			if (value) {
				if (!this.data.VAT) {
					return (
						"(" +
						this.numberRUFormat(valueToValueWithVAT(value)) +
						" руб. С НДС)"
					);
				} else {
					return (
						"(" +
						this.numberRUFormat(valueToValueWithoutVAT(value)) +
						" руб. Без НДС)"
					);
				}
			} else {
				return "";
			}
		},
		numberRUFormat(value) {
			return numberToRUFormat(value);
		},
		tenderComputing(index) {
			if (
				this.data.routeWays[index].startDate &&
				this.data.routeWays[index].stopDate
			) {
				const start = moment(this.data.routeWays[index].startDate);
				const stop = moment(this.data.routeWays[index].stopDate);
				const days = stop.diff(start, "days") + 1;

				const startWeekday = moment(
					this.data.routeWays[index].startDate
				).format("e");
				const stopWeekday = moment(
					this.data.routeWays[index].stopDate
				).format("e");

				let extraDaysCount = 0;
				if (startWeekday === "0") {
					extraDaysCount = 1 + Number(stopWeekday);
				} else if (startWeekday === "1") {
					extraDaysCount = Number(stopWeekday);
				} else {
					extraDaysCount =
						8 - Number(startWeekday) + Number(stopWeekday);
				}
				const fullWeeks = Math.floor(days / 7);

				let missingDaysCount = 0;
				if (startWeekday === "0") {
					missingDaysCount = 6;
				} else {
					missingDaysCount = Number(startWeekday) - 1;
				}
				if (stopWeekday !== "0") {
					missingDaysCount =
						missingDaysCount + 7 - Number(stopWeekday);
				}
				const allWeeks = (days + missingDaysCount) / 7;
				this.data.routeWays[index].weekCount = allWeeks;

				let allRoutes = 0;
				const weekdaysCounters = [
					fullWeeks,
					fullWeeks,
					fullWeeks,
					fullWeeks,
					fullWeeks,
					fullWeeks,
					fullWeeks,
				];
				if (allWeeks > fullWeeks) {
					if (startWeekday <= stopWeekday) {
						for (let i = startWeekday; i <= stopWeekday; i++) {
							weekdaysCounters[i]++;
						}
					} else {
						if (Number(stopWeekday) > 0) {
							for (let i = stopWeekday; Number(i) > 0; i--) {
								weekdaysCounters[i]++;
							}
						}
						for (let i = startWeekday; Number(i) <= 6; i++) {
							weekdaysCounters[i]++;
						}
						weekdaysCounters[0]++;
					}
				}
				allRoutes =
					this.data.routeWays[index].weekTemplate.monday.length *
						weekdaysCounters[1] +
					this.data.routeWays[index].weekTemplate.tuesday.length *
						weekdaysCounters[2] +
					this.data.routeWays[index].weekTemplate.wednesday.length *
						weekdaysCounters[3] +
					this.data.routeWays[index].weekTemplate.thursday.length *
						weekdaysCounters[4] +
					this.data.routeWays[index].weekTemplate.friday.length *
						weekdaysCounters[5] +
					this.data.routeWays[index].weekTemplate.saturday.length *
						weekdaysCounters[6] +
					this.data.routeWays[index].weekTemplate.sunday.length *
						weekdaysCounters[0];
				this.data.routeWays[index].routeCount = allRoutes;
			} else {
				this.data.routeWays[index].weekCount = null;
				this.data.routeWays[index].routeCount = null;
			}
		},
		prevBet(index) {
			let maximalNewBet = null;
			if (this.data.routeWays[index].smallestBid) {
				maximalNewBet = this.data.routeWays[index].smallestBid;
			} else {
				maximalNewBet = this.data.routeWays[index].routePrice;
			}
			if (this.form.routeWays[index].newBet < maximalNewBet) {
				this.form.routeWays[index].newBet =
					this.form.routeWays[index].newBet +
					this.data.routeWays[index].stepRate;
			}
		},
		nextBet(index) {
			if (
				this.form.routeWays[index].newBet >
				this.data.routeWays[index].stepRate
			) {
				this.form.routeWays[index].newBet =
					this.form.routeWays[index].newBet -
					this.data.routeWays[index].stepRate;
			}
		},
		getFormData: function(object) {
			const str = [];
			for (const p in object) {
				if (object.hasOwnProperty(p)) {
					str.push(
						encodeURIComponent(p) +
							"=" +
							encodeURIComponent(object[p])
					);
				}
			}
			return str.join("&");
		},
		async contractorsTenderRead() {
			this.loading = true;
			const response = await contractorsTenderRead(
				this.contractorId,
				this.tendersId
			);
			if (response && response.status === 200) {
        if (response.data.status) {
          this.data.status = response.data.status;
        }
        if (response.data.enableOffers) {
          this.data.enableOffers = response.data.enableOffers;
        }
				if (response.data.winner) {
					this.data.winner = response.data.winner;
				}
				if (response.data.name) {
					this.data.name = response.data.name;
				}

        if (response.data.additionalWayPointPrice) {
          this.data.additionalWayPointPrice = response.data.additionalWayPointPrice;
        }

        if (response.data.withNDS) {
          this.data.VAT = response.data.withNDS;
        }

				if (response.data.summ) {
					this.data.totalPrice = response.data.summ;
				}
				if (response.data.activeProposal?.sum) {
					this.data.activeProposalSum =
						response.data.activeProposal.sum;
				}
				if (response.data.contractorProposal) {
					this.data.contractorProposal =
						response.data.contractorProposal;
					this.data.contractorProposalDifference =
						this.data.contractorProposal.sum -
						this.data.activeProposalSum;
				}
				if (response.data.organization) {
					this.form.organizations.value =
						response.data.organization.name;
				}
				if (response.data.startDate) {
					this.data.startDate = response.data.startDate;
				}
				if (response.data.factEndDate) {
					this.data.endDate = response.data.factEndDate;
				} else if (response.data.stopDate) {
					if (this.data.endDate !== response.data.stopDate) {
						this.data.endDate = response.data.stopDate;

						let eventTime;
						if (response.data.status === 1) {
							eventTime = moment(response.data.startDate).format(
								"x"
							);
						} else {
							eventTime = moment(response.data.stopDate).format(
								"x"
							);
						}
						const currentTime = moment().format("x");
						const diffTime = eventTime - currentTime;
						let duration = moment.duration(diffTime);
						const interval = 1000;

						setInterval(() => {
							duration = moment.duration(
								duration - interval,
								"milliseconds"
							);
							if (duration.days() === 0) {
								this.data.countdown =
									duration.hours() +
									" ч. " +
									duration.minutes() +
									" м. " +
									duration.seconds() +
									" сек.";
							} else {
								this.data.countdown =
									duration.days() +
									" д. " +
									duration.hours() +
									" ч. " +
									duration.minutes() +
									" м. " +
									duration.seconds() +
									" сек.";
							}
						}, interval);
					}
				}
				if (response.data.routeTemplates.length > 0) {
					response.data.routeTemplates.forEach(
						(routeTemplate, index) => {
							if (index) {
								this.addRouteWay(index);
							}
							this.data.routeWays[index].routeNumber = index;
							if (routeTemplate.id) {
								this.data.routeWays[index].id =
									routeTemplate.id;
							}
							if (routeTemplate.routeWay) {
							Vue.set(
								this.form.routeWays[index],
								"note",
								{id: null,
								value: null}
								)
								this.data.routeWays[index].playedRouteWay =
									routeTemplate.routeWay.code;
								this.data.routeWays[
									index
								].points = routeTemplate.routeWay.routeWayPoints.sort(
									(a, b) => a.rowNumber - b.rowNumber
								);
								this.data.routeWays[
									index
								].fullDistance = routeTemplate.routeWay.routeWayPoints
									.reduce(function(
										accumulator,
										currentValue
									) {
										return (
											accumulator +
											Number(currentValue.distance)
										);
									},
									0)
									.toFixed(2);
								this.data.routeWays[
									index
								].timeSummer = routeTemplate.routeWay.routeWayPoints
									.reduce(function(
										accumulator,
										currentValue
									) {
										return (
											accumulator +
											Number(currentValue.timeSummer)
										);
									},
									0)
									.toFixed(2);
								const departments = [];
								routeTemplate.routeWay.routeWayPoints.forEach(
									(point) => {
										departments.push(point.department.name);
									}
								);
								this.data.routeWays[
									index
								].department = departments;
							}
							if (routeTemplate.transportation) {
								this.data.routeWays[index].transportationName =
									routeTemplate.transportation.name;
							}
							if (routeTemplate.routeType) {
								this.data.routeWays[index].routeType = routeTemplate.routeType;
							} else {
							this.data.routeWays[index].routeType = 'oneWay';
							}
							if (routeTemplate.routeWay.transportationType) {
								this.data.routeWays[index].transportationType =
									routeTemplate.routeWay.transportationType.name;
							}
							if (routeTemplate.routeWay.code) {
								this.data.routeWays[index].routeWayCode =
									routeTemplate.routeWay.code;
							}
							if (routeTemplate.routeSumm) {
								this.data.routeWays[index].routePrice = Number(
									routeTemplate.routeSumm
								);
							}
							if (routeTemplate.winRouteSumm) {
								this.data.routeWays[
									index
								].winRouteSumm = Number(
									routeTemplate.winRouteSumm
								);
							}
							if (routeTemplate.templateSumm) {
								this.data.routeWays[index].templatePrice =
									routeTemplate.templateSumm;
							}
							if (routeTemplate.step) {
								this.data.routeWays[index].stepRate = Number(
									routeTemplate.step
								);
							}
							if (routeTemplate.smallestBid && routeTemplate.smallestBid !== 0) {
								this.data.routeWays[index].smallestBid =
									routeTemplate.smallestBid.bet;
								this.form.routeWays[index].newBet =
									routeTemplate.smallestBid.bet;
							} else {
								this.form.routeWays[index].newBet = Number(
									routeTemplate.routeSumm
								);
							}
							if (routeTemplate.smallestContractorBid) {
								this.data.routeWays[index].switcher = false;
								this.data.routeWays[
									index
								].smallestContractorBid = Number(
									routeTemplate.smallestContractorBid
								);
							}
							this.data.routeWays[index].contractorOffer = {};
							if (routeTemplate.contractorOffer) {
								this.data.routeWays[index].switcher = true;
								this.data.routeWays[index].contractorOffer =
									routeTemplate.contractorOffer;
								this.form.routeWays[index].newOffer =
									routeTemplate.contractorOffer.sum;
							} else {
								this.form.routeWays[index].newOffer = Number(
									routeTemplate.routeSumm
								);
							}
							this.data.routeWays[index].more = false;
							if (routeTemplate.periodStart) {
								const clientTimezone = Intl.DateTimeFormat().resolvedOptions()
									.timeZone;
								this.data.routeWays[index].startDate = moment(
									routeTemplate.periodStart,
									"YYYY-MM-DDTHH:mm:ss"
								)
									.tz(clientTimezone)
									.format();
							}
							if (routeTemplate.periodStop) {
								const clientTimezone = Intl.DateTimeFormat().resolvedOptions()
									.timeZone;
								this.data.routeWays[index].stopDate = moment(
									routeTemplate.periodStop,
									"YYYY-MM-DDTHH:mm:ss"
								)
									.tz(clientTimezone)
									.format();
							}
							if (routeTemplate.routeDateTemplates.length > 0) {
								this.data.routeWays[index].weekTemplate = {
									monday: [],
									tuesday: [],
									wednesday: [],
									thursday: [],
									friday: [],
									saturday: [],
									sunday: [],
								};
								routeTemplate.routeDateTemplates.forEach(
									(routeDateTemplate) => {
										const routeDateTemplateDateParams = routeDateTemplate.dateTemplate.split(
											" "
										);
										this.data.routeWays[index].weekTemplate[
											routeDateTemplateDateParams[0]
										].push({
											weekday:
												routeDateTemplateDateParams[0],
											time:
												routeDateTemplateDateParams[
													routeDateTemplateDateParams.length -
														1
												],
										});
									}
								);
							}
							if (routeTemplate.loadingTypes.length > 0) {
								this.data.routeWays[index].loadingUnloading =
									routeTemplate.loadingTypes;
							}
							if (routeTemplate.bodyTypes) {
								this.data.routeWays[index].bodyType =
									routeTemplate.bodyTypes;
							}
							this.data.routeWays[index].dimensions = {
								id: routeTemplate.routeWay.id,
								dimension: routeTemplate.dimension,
								optionalDimensions:
									routeTemplate.optionalDimensions,
							};
							this.tenderComputing(index);
						}
					);
				}
				if (response.data.routeTemplates) {
            response.data.routeTemplates.forEach((route, index) => {
				if (route.comment) {
					Vue.set(this.form.routeWays[index], "note", {value: route.comment, id: route.id});
				}
            });       
        }
				this.collectData();
			}
			this.loading = false;
		},
		async onTenderBid(index) {
			this.loading = true;
			if (this.form.routeWays[index].newBet) {
				this.userPrice = Number(this.form.routeWays[index].newBet);
			}
			if (this.data.routeWays[index].smallestBid && this.data.routeWays[index].smallestBid !== 0) {
				this.storePrice = Number(this.data.routeWays[index].smallestBid);
			}		
			this.tenderRouteWay = this.data.routeWays[index].playedRouteWay;
			if (this.data.routeWays[index].routePrice && this.data.routeWays[index].routePrice !== 0) {
				this.tenderStartPrice = this.data.routeWays[index].routePrice;
			}
			const response = await contractorsTendersRouteTemplateBids(
				this.contractorId,
				this.tendersId,
				this.data.routeWays[index].id,
				this.getFormData({
					summ: Number(this.form.routeWays[index].newBet),
				})
			);
			if (response && response.status === 201) {
				this.showSuccess("Ставка сделана");
				this.contractorsTenderRead();
			}
			this.loading = false;
		},
		confirmTenderOffer(index) {
			this.$bvModal
				.msgBoxConfirm(
					"Если Вы сделаете встречное предложение, то не сможете делать ставки по данному маршруту, до тех пор пока не отмените встречное предложение.",
					{
						title:
							"Вы уверены что хотите сделать встречное предложение?",
						okTitle: "Да",
						cancelTitle: "Назад",
					}
				)
				.then((confirm) => {
					if (confirm) {
						this.onTenderOffer(index);
					}
				});
		},
		async onTenderOffer(index) {
			this.loading = true;
			if (this.form.routeWays[index].newOffer) {
				this.userPrice = this.form.routeWays[index].newOffer;
			}
			if (this.data.routeWays[index].routePrice && this.data.routeWays[index].routePrice !== 0) {
				this.tenderStartPrice = this.data.routeWays[index].routePrice;
			}
			if (this.data.routeWays[index].smallestBid && this.data.routeWays[index].smallestBid !== 0) {
				this.storePrice = Number(this.data.routeWays[index].smallestBid);
			}
			this.tenderRouteWay = this.data.routeWays[index].playedRouteWay;
			let response = null;
			if (this.data.routeWays[index].contractorOffer.id) {
				response = await contractorsTendersRouteTemplateEditOffer(
					this.contractorId,
					this.tendersId,
					this.data.routeWays[index].id,
					this.data.routeWays[index].contractorOffer.id,
					this.form.routeWays[index].newOffer
				);
			} else {
				response = await contractorsTendersRouteTemplateOffer(
					this.contractorId,
					this.tendersId,
					this.data.routeWays[index].id,
					this.getFormData({
						summ: Number(this.form.routeWays[index].newOffer),
					})
				);
			}
			if (response && response.status === 201) {
				this.showSuccess("Предложение отправлено");
				this.contractorsTenderRead();
			} else if (response && response.status === 200) {
				this.showSuccess("Предложение обновлено");
				this.contractorsTenderRead();
			} else {
				this.loading = false;
			}
		},
		async onTenderDeleteOffer(index) {
			this.loading = true;
			const response = await contractorsTendersRouteTemplateDeleteOffer(
				this.contractorId,
				this.tendersId,
				this.data.routeWays[index].id
			);
			if (response && response.status === 200) {
				this.showSuccess("Предложение удалено");
				this.userPrice = "0";
				if (this.data.routeWays[index].routePrice && this.data.routeWays[index].routePrice !== 0) {
					this.tenderStartPrice = this.data.routeWays[index].routePrice;
				}
				if (this.data.routeWays[index].smallestBid && this.data.routeWays[index].smallestBid !== 0) {
					this.storePrice = Number(this.data.routeWays[index].smallestBid);
				}
				this.contractorsTenderRead();
			} else {
				this.loading = false;
			}
		},
		async onCancel(type) {
			this.loading = true;
			const response = await contractorsTenderDeclineWin(
				this.contractorId,
				this.tendersId
			);
			if (response && response.status === 200) {
				this.showSuccess("Вы отказались от победы в тендере");
				this.contractorsTenderRead();
			}
			this.loading = false;
		},
		addRouteWay(index) {
			if (!this.form.routeWays[index]) {
				this.form.routeWays.push({
					note: {
						id: null,
						value: null,
					},
					newBet: null,
					newOffer: null,
				});
				this.data.routeWays.push({
					id: null,
					points: [],
					routeNumber: null,
					routeWayCode: null,
					routeCount: null,
					templatePrice: null,
					routePrice: null,
					routeType: 'oneWay',
					stepRate: null,
					smallestBid: null,
					smallestContractorBid: null,
					switcher: false,
					contractorOffer: {},
					more: false,
					fullDistance: null,
					timeSummer: null,
					playedRouteWay: null,
					size: null,
					loadingUnloading: [],
					bodyType: [],
					startDate: null,
					stopDate: null,
					weekCount: null,
					department: [],
					weekTemplate: {
						monday: [],
						tuesday: [],
						wednesday: [],
						thursday: [],
						friday: [],
						saturday: [],
						sunday: [],
					},
				});
			}
		},
		async collectData() {
			let dateNow = new Date(),
				currentPrice = [],
				routeCode = [],
				data = {
					date: dateNow.toLocaleDateString("ru-Ru", {timeZone: "Europe/Moscow"}),
					time: dateNow.toLocaleTimeString("ru-Ru", {timeZone: "Europe/Moscow"}),
					organization: this.$store.getters["user"].company.replace(
						/[^А-яЁё A-Za-z]/g,
						""
					),
					pageType: "Тендер",
					sessionId: this.$cookies.get("Session"),
					clickType: "Зашел в закупку",
					purchaseId: Number(this.tendersId),
					startPrice:
						Number(this.tenderStartPrice) ||
						Number(this.data.totalPrice),
					currentPrice: currentPrice,
					routeCode: routeCode,
					inn: this.$store.getters["user"].INN,
				};

			this.data.routeWays.forEach((routeWay) => {
				if (routeWay.smallestBid) {
					data["currentPrice"].push(Number(routeWay.smallestBid));
				} else {
					data["currentPrice"].push(Number(routeWay.routePrice));
				}
			});

			if (this.data.activeProposalSum) {
				data["totalPrice"] = Number(this.data.activeProposalSum);
			} else {
				data["totalPrice"] = Number(data["startPrice"]);
			}

			if (this.tenderRouteWay) {
				data["routeCode"].push(this.tenderRouteWay);
			} else {
				data["routeCode"] = [];
				for (let item in this.data.routeWays) {
					data["routeCode"].push(
						this.data.routeWays[item].routeWayCode
					);
				}
			}

			if (
				data["clickType"] === "Зашел в закупку" ||
				data["clickType"] === "Отмена заявки в закупке"
			) {
				data["winner"] = null;
			}

			if (this.data.status === 6 || this.data.status === 7) {
				data["currentPrice"] = [];
				this.data.routeWays.forEach((routeWay) => {
				if (routeWay.smallestBid) {
					data["currentPrice"].push(Number(routeWay.smallestBid));
				} else {
					data["currentPrice"].push(Number(routeWay.routePrice));
				}
				});
				data["clickType"] = "Итоги подведены";
				data["totalPrice"] = Number(this.data.contractorProposal.sum);

				if (this.data.contractorProposal.winner == true) {
					data["winner"] = "Да";
				} else {
					data["winner"] = "Нет";
				}
				tenderBet(data);
			}

			if (this.userPrice && this.userPrice !== "0") {
				data["newPrice"] = this.userPrice;
				data["clickType"] = "Подача заявки в закупке";

				if (data["newPrice"]) {
					data["currentPrice"] = [];
					if (Number(this.storePrice) === 0) {
						data["currentPrice"].push(Number(data["startPrice"]));
					} else {
						data["currentPrice"].push(Number(this.storePrice));
					}
				}
				data["totalPrice"] = Number(this.data.contractorProposal.sum);
				await tenderBet(data);
			} else if (this.userPrice === "0") {
				data["newPrice"] = "0";
				data["clickType"] = "Отмена заявки в закупке";
				data["currentPrice"] = [];
				if (Number(this.storePrice) === 0) {
					data["currentPrice"].push(Number(this.tenderStartPrice));
				} else {
					data["currentPrice"].push(Number(this.storePrice));
				}
				
				await tenderBet(data);
			} else if (this.data.status === 2) {
				await enterToTenderPage(data);
			}
		},
	},
};
</script>

<style lang="scss">
.tender-info {
	overflow: hidden;
	background: #f3f4f5;
	border-radius: 5px;

	&__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-left: -15px;
		margin-right: -15px;
		padding: 5px 10px;
		border-bottom: 1px solid #c8ced3;

		&-item {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	&__body {
		padding: 5px 10px;

		&-title {
			margin-top: 24px;
			margin-bottom: 24px;
			font-size: 36px;
			font-weight: 700;
			text-align: center;
		}
		&-subtitle {
			margin-top: 24px;
			margin-bottom: 24px;
			font-size: 16px;
			font-weight: 700;
			text-align: center;
		}
		&-dates {
			display: flex;
			max-width: 480px;
			margin: 24px auto;
		}
		&-start-date {
			flex: 0 0 50%;
			max-width: 50%;
			font-size: 14px;
		}
		&-end-date {
			flex: 0 0 50%;
			max-width: 50%;
			font-size: 14px;
			text-align: right;
		}
		&-buttons {
			display: flex;
			justify-content: center;
			margin-top: 60px;
		}
	}
	&__previous-bet {
		position: relative;
		min-height: 100%;
		padding: 5px 10px;

		&:after {
			position: absolute;
			top: 0;
			right: 0;
			transform: translateX(50%);
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
		}

		&-title {
			font-weight: 700;
			font-size: 14px;
		}
		&-list {
			max-height: 271px;
			overflow: auto;
			background-color: white;
			border: 1px solid #c8ced3;
		}
		&-item {
			padding: 2px 5px;
			border-bottom: 1px solid #c8ced3;

			&:last-child {
				border-bottom: 0;
			}

			&-header {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;
			}
			&-date {
				font-weight: 700;
				font-size: 11px;
			}
			&-type {
				font-size: 11px;
			}
			&-info {
				position: relative;
				display: flex;
				justify-content: space-between;
				margin-top: 10px;
				margin-bottom: 10px;

				&:before {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 28px;
					height: 2px;
					background-color: #20a8d8;
					content: "";
				}
			}
			&-contractor {
				flex: 0 0 50%;
				max-width: 50%;
				padding-right: 15px;
				font-weight: 700;
				font-size: 13px;
			}
			&-price {
				flex: 0 0 50%;
				max-width: 50%;
				padding-left: 15px;
				font-weight: 700;
				font-size: 14px;
			}
			&-buttons {
				display: flex;
				justify-content: center;
				margin-top: 10px;
			}
		}
	}
	&__last-bet {
		position: relative;
		padding: 5px 10px;

		&-title {
			margin-bottom: 24px;
			font-weight: 700;
			font-size: 36px;
			text-align: center;
		}
		&-time {
			position: absolute;
			top: 5px;
			right: 10px;
			margin-top: 0;
			margin-bottom: 0;
			font-weight: 700;
			line-height: 27px;
			text-align: right;
		}
		&-current-bid {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;
			margin-bottom: 16px;

			&-title {
				min-width: 200px;
				font-weight: 700;
				font-size: 14px;
			}
			&-value {
				font-weight: 700;
				font-size: 28px;
				line-height: normal;
				color: #4dbd74;

				&_no-winner {
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					color: #c8ced3;
				}

				&-vat {
					font-size: 12px;
					color: #636f83;
				}
			}
		}
		&-current-contractor {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;
			margin-bottom: 16px;

			&-title {
				min-width: 200px;
				font-weight: 700;
				font-size: 14px;
			}
			&-value {
				font-style: italic;
				font-weight: 700;
				font-size: 18px;
				line-height: normal;
				color: #20a8d8;

				&_no-winner {
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					color: #c8ced3;
				}
			}
		}
		&-current-time {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;
			margin-bottom: 16px;

			&-title {
				min-width: 200px;
				font-weight: 700;
				font-size: 14px;
			}
			&-value {
				font-weight: 700;
				font-size: 14px;
				color: #20a8d8;

				&_no-winner {
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					color: #c8ced3;
				}
			}
		}
		&-buttons {
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}
	}
}

.contractors-tender-info {
	position: sticky;
	top: 65px;
	background: #f3f4f5;
	border: 1px solid #c8ced3;

	&.draft {
		&:after {
			display: none;
		}
	}

	&__top {
		position: relative;

		&:after {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
		}
	}

	&__bet {
		&-timer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 5px 10px;
			border-bottom: 1px solid #c8ced3;

			&-title {
				font-size: 14px;
				font-weight: 700;
			}

			&-value {
				font-size: 14px;
				font-weight: 700;
			}
		}

		&-current-bet {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 5px 10px;
			border-bottom: 1px dashed #c8ced3;

			&-title {
				font-size: 14px;
				font-weight: 700;
			}

			&-value {
				&-number {
					display: block;
					font-size: 16px;
					font-weight: 700;
					text-align: right;
				}

				&-with-vat {
					display: block;
					font-size: 10px;
					font-style: italic;
					font-weight: 700;
					text-align: right;
				}
			}
		}

		&-my-bet {
			padding: 5px 10px;

			&-title {
				margin-top: 0;
				margin-bottom: 0;
				font-weight: 700;
				font-size: 28px;
				line-height: normal;
				text-align: center;
			}

			&-desc {
				max-width: 320px;
				margin: 0 auto;
				font-weight: 700;
				font-size: 16px;
				line-height: normal;
				text-align: center;
			}

			&-status {
				margin-top: 0;
				margin-bottom: 0;
				font-weight: 700;
				font-size: 16px;
				line-height: normal;
				text-align: center;
			}

			&-field {
				position: relative;
				display: block;
				margin-top: 24px;
				margin-bottom: 30px;
				padding-left: 50px;
				padding-right: 50px;
				background-color: #ebedef;
				cursor: pointer;

				&-minus {
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 45px;
					height: 45px;
					padding: 0;
					background-color: #d8dbe0;
					border: 0;
					font-size: 20px;
					color: #20a8d8;
				}

				&-value {
					display: block;
					font-size: 24px;
					line-height: 45px;
					text-align: center;
				}

				&-input {
					width: auto;
					background-color: transparent;
					border: 0;
					text-align: center;
					-moz-appearance: textfield;
					appearance: textfield;

					&:focus {
						outline: 0;
					}
				}

				&-currency {
					position: absolute;
					right: 5px;
					top: 50%;
					transform: translateY(-50%);
				}

				&-desc {
					position: absolute;
					top: 100%;
					left: 0;
					display: block;
					width: 100%;
					font-size: 12px;
					line-height: normal;
					text-align: center;
				}

				&-plus {
					position: absolute;
					top: 0;
					right: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 45px;
					height: 45px;
					padding: 0;
					background-color: #d8dbe0;
					border: 0;
					font-size: 20px;
					color: #20a8d8;
				}
			}

			&-button {
				display: block;
				width: 100%;
			}
		}
	}

	&__data {
		padding: 5px 10px;

		&-item {
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-title {
			font-weight: 700;
			font-size: 14px;
			margin-bottom: 16px;
		}

		&-subtitle {
			font-weight: 700;
			font-size: 14px;
			color: #768192;
			margin-bottom: 8px;
		}

		&-value {
			display: block;
			font-weight: 700;
			font-size: 14px;
			margin-bottom: 0;

			&-desc {
				display: block;
				font-size: 11px;
				margin-bottom: 8px;
			}
		}

		&-date {
			font-size: 12px;
		}
	}

	&__bottom {
		border-top: 1px solid #c8ced3;
		padding: 5px 10px;
	}

	&__offer {
		&-title {
			margin-bottom: 12px;
			font-weight: 700;
			font-size: 14px;
		}

		&-input {
			margin-bottom: 0;
			padding-right: 10px;
		}

		&-button-container {
			padding-left: 10px;
			padding-right: 10px;
		}

	}
}

.history-tender {
	&__list {
		list-style: inside none;
		overflow: auto;
		height: 444px;
		padding: 10px 5px;
		margin-bottom: 0;
		background-color: #2b2b2b;

		&-item {
			margin-bottom: 12px;

			&:last-child {
				margin-bottom: 0;
			}

			&-date {
				color: #797979;
			}
			&-changes {
				padding-left: 0;
				list-style-position: inside;
			}
		}
	}
}

.VueTables__table {
	margin-bottom: 2rem;
}

.route-tender {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 480px;
	height: 100%;

	&:before {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 3px;
		height: 100%;
		background-color: #c8ced3;
		content: "";
	}

	&__item {
		position: relative;
		z-index: 1;
		max-width: 480px;
		padding: 10px 6px;
		margin-top: 18px;
		background: #f3f4f5;
		border: 1px solid #c8ced3;

		&:first-child {
			margin-top: 0;

			&:before {
				display: none;
			}
		}

		&:before {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			border: 10px solid transparent;
			border-top: 10px solid #c8ced3;
			content: "";
		}

		&-header {
			display: flex;
			justify-content: space-between;
			@media screen and (max-width: 767px) {
				flex-wrap: wrap;
			}
		}
		&-number {
			padding-right: 20px;
			white-space: nowrap;
		}
		&-type {
			width: auto;
			min-width: 136px;
			padding: 0 5px;
		}
		&-distance {
			padding-left: 20px;
			white-space: nowrap;
			@media screen and (max-width: 767px) {
				width: 100%;
				padding-left: 0;
			}
		}
		&-address {
			margin-top: 12px;
			margin-bottom: 0;
		}
		&-footer {
			padding-top: 28px;
			display: flex;
			justify-content: space-between;
		}
	}
}

.route-way-week {
	margin-bottom: 16px;

	&__head {
		display: flex;

		&-item {
			display: block;
			flex: 0 0 14.2857%;
			max-width: 14.2857%;
			text-align: center;
			border: 1px solid gray;
			transition: all 0.33s;

			&.active {
				background-color: #20a8d8;
				border-color: #20a8d8;
				color: white;
			}
		}
	}
	&__body {
		display: flex;

		&-weekday {
			display: block;
			flex: 0 0 14.2857%;
			max-width: 14.2857%;
			padding-left: 2px;
			padding-right: 2px;
		}
		&-item {
			position: relative;
			display: block;
			padding-top: 24px;
			margin-bottom: 0;

			&:before {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				display: block;
				width: 3px;
				height: 24px;
				background-color: rgba(#20a8d8, 0.25);
				content: "";
			}
		}
		&-time {
			height: 20px;
			padding: 2px;
			font-size: 16px;
			line-height: 1;
			text-align: center;
			-moz-appearance: textfield;
			appearance: textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration {
				display: none;

				&:hover {
					display: none;
				}
			}

			::-ms-clear,
			::-ms-reveal {
				display: none;
				width: 0;
				height: 0;
			}

			&:focus,
			&:hover {
				-moz-appearance: number-input;
				appearance: number-input;
			}
		}
		&-remove {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			padding: 0;
			background-color: transparent;
			border: 0;
			font: normal normal normal 20px/1 FontAwesome;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			color: red;
			cursor: pointer;
			transition: color 0.33s;

			&:before {
				content: "\f00d";
			}
		}
		&-add {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 16px;
			height: 16px;
			margin: 24px auto 0;
			padding: 0;
			background-color: transparent;
			border: 0;
			font: normal normal normal 16px/1 FontAwesome;
			text-align: center;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			color: #20a8d8;
			cursor: pointer;

			&:before {
				position: absolute;
				bottom: 100%;
				left: 50%;
				transform: translateX(-50%);
				display: block;
				width: 3px;
				height: 24px;
				background-color: rgba(#20a8d8, 0.25);
				content: "";
			}
			&:after {
				margin-top: -2px;
				margin-left: -1.5px;
				content: "\f0fe";
			}

			&:first-child {
				display: none;
			}
		}
	}
}

.terms-and-participants {
	&__contractors {
		padding: 1rem;
	}
	&__start-price {
		position: relative;
		height: 100%;
		padding: 1rem;

		&:before {
			position: absolute;
			top: 0;
			left: -0.5px;
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
			@media screen and (max-width: 991px) {
				left: 0;
				width: 100%;
				height: 1px;
			}
		}
	}
	&__tender-duration {
		position: relative;
		margin-top: 32px;
		padding-top: 20px;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			border: 1px dashed #c8ced3;
			content: "";
		}
	}
}

.tab-content {
	.tab-pane.terms-and-participants {
		padding: 0;
	}
}

/* Update tenders styles */
.tender-page {
	&__title {
		font-size: 40px;
		line-height: 1;
		margin-bottom: 40px;
	}

	.form-group {
		> label,
		> legend {
			font-size: 16px;
			line-height: 21px;
			font-weight: bold;
		}
	}
}

.tender-status-card {
	overflow: hidden;
	padding: 40px 30px;
	margin-top: 30px;
	margin-bottom: 50px;
	background-color: white;
	border-radius: 4px 4px 0 0;
	box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);

	&__title {
		margin-top: 0;
		margin-bottom: 24px;
		font-size: 28px;
		line-height: 34px;
		font-weight: bold;
		color: #000;
		text-align: center;
	}
	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 60px;
		color: rgba(#000, 0.6);

		&-container {
			position: relative;
			display: block;
			width: 88px;
			height: 88px;
			margin: 24px auto 28px;
			border-radius: 50%;

			&_warning {
				background-color: #ffc107;
			}
			&_success {
				background-color: #4dbd74;
			}
		}
	}
	&__desc {
		margin-top: 28px;
		margin-bottom: 24px;
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;
		color: #768192;
		text-align: center;
	}
}

.tender-info {
	overflow: hidden;
	padding: 20px 30px;
	margin-top: 20px;
	margin-bottom: 30px;
	background-color: white;
	border-radius: 4px 4px 0 0;
	box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);

	&__body {
		&-title {
			text-align: left;
			font-size: 40px;
			line-height: 1;
			margin-bottom: 40px;

			&-status {
				position: relative;
				top: -7px;
				display: inline-block;
				padding-left: 10px;
				padding-right: 10px;
				margin-left: 10px;
				border-radius: 13px;
				font-size: 14px;
				line-height: 25px;
			}
		}
	}
	&__nds {
		margin-bottom: 24px;

		&-title {
			margin-bottom: 12px;
			font-size: 16px;
			line-height: 21px;
			color: #768192;

			b {
				color: #23282c;
			}
		}
		&-checkbox {
			margin-bottom: 12px;
			font-size: 16px;
			line-height: 21px;
			color: #768192;
			cursor: pointer;
		}
	}
	&__item {
		margin-bottom: 24px;

		&-title {
			margin-bottom: 12px;
			font-size: 16px;
			line-height: 21px;
			font-weight: bold;
		}
		&-desc {
			margin-top: 12px;
			margin-bottom: 12px;
			font-size: 16px;
			line-height: 21px;
			color: #768192;

			&-note {
				font-size: 0.75em;
				line-height: 1;
				font-style: italic;
				font-weight: 700;
			}
		}
	}
}

.tender-route-main-info {
	padding: 20px 30px 0;
	margin-top: 30px;
	margin-bottom: 60px;
	background-color: #f0f3f5;
	border-radius: 4px 4px 0 0;
	box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);

	&__header {
		padding-bottom: 4px;
		margin-bottom: 32px;
		border-bottom: 1px solid #c8ced3;
	}
	&__title {
		margin-bottom: 12px;
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;
	}
	&__route {
		padding-left: 0;
		margin-bottom: 12px;
		list-style: none inside;

		&-first {
			display: inline-block;
			padding-right: 20px;
		}
		&-last {
			display: inline-block;
			position: relative;
			padding-left: 20px;
			text-align: right;

			&:after {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(-50%, -100%);
				display: block;
				width: 20px;
				height: 2px;
				background-color: #20a8d8;
				content: "";
			}
		}
	}
	&__desc {
		margin-top: 12px;
		margin-bottom: 12px;
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;
		color: #768192;
	}
	&__body {
		&-bid,
		&-offer {
			&.disabled {
				.tender-route-main-info {
					&__title,
					&__desc,
					&__bet-desc,
					&__bet-field,
					&__offer-desc {
						opacity: 0.5;
					}
				}
			}
		}
	}
	&__bet {
		&-field {
			position: relative;
			display: block;
			min-height: 50px;
			padding-left: 48px;
			padding-right: 48px;
			background-color: #fff;
			border: 1px solid #fff;
			border-radius: 4px;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

			&-minus,
			&-plus {
				position: absolute;
				top: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 32px;
				height: 32px;
				padding: 0;
				background-color: #20a8d8;
				border: 0;
				border-radius: 4px;
				font-size: 0;
				color: #20a8d8;

				&:before {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					display: block;
					width: 24px;
					height: 8px;
					background-color: #fff;
					content: "";
				}
			}

			&-minus {
				left: 8px;
			}

			&-value {
				position: relative;
				display: block;
				font-size: 36px;
				line-height: 48px;
				text-align: center;
			}

			&-plus {
				right: 8px;

				&:after {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					display: block;
					width: 8px;
					height: 24px;
					background-color: #fff;
					content: "";
				}
			}
		}
		&-desc {
			margin-top: 0;
			margin-bottom: 8px;
			font-size: 12px;
			line-height: 21px;
			font-style: italic;
			text-align: center;
			color: #050505;

			&-note {
				font-size: 1em;
				line-height: 1;
				font-style: italic;
				font-weight: 700;
			}
		}
		&-button {
			display: block;
			width: 100%;
			background-color: #4dbd74;
			border-radius: 4px;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			font-size: 20px;
			line-height: 28px;
			font-weight: bold;
			color: #fff;

			&.disabled {
				opacity: 0.5;
			}
		}
	}
	&__switcher {
		&-container {
			margin-top: 30px;
			margin-bottom: 30px;
		}
		&-input {
			border: 0;
			clip: rect(0 0 0 0);
			-webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
			clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
			white-space: nowrap;

			&:disabled ~ .tender-route-main-info__switcher-display {
				opacity: 0.5;
				cursor: default;
			}
		}
		&-display {
			position: relative;
			padding-left: 21px;
			font-size: 13px;
			line-height: 21px;
			font-weight: bold;
			text-transform: uppercase;
			color: #20a8d8;
			text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			cursor: pointer;

			&-icon {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				color: inherit;
			}
		}
	}
	&__offer {
		&-field {
			margin: 0;
		}
		&-input {
			position: relative;
			display: block;
			height: auto;
			padding: 0 8px;
			margin: 0;
			font-size: 36px;
			line-height: 48px;
			text-align: center;
			color: #050505;
			background-color: #fff;
			border: 1px solid #fff;
			border-radius: 4px;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

			&:focus {
				color: #050505;
			}

			&:disabled {
				opacity: 0.5;
				background-color: #fff;
			}
		}
		&-desc {
			margin-top: 0;
			margin-bottom: 8px;
			font-size: 12px;
			line-height: 21px;
			font-style: italic;
			text-align: center;
			color: #050505;

			&-note {
				font-size: 1em;
				line-height: 1;
				font-style: italic;
				font-weight: 700;
			}
		}
		&-button {
			display: block;
			width: 100%;
			background-color: #ffc107;
			border-radius: 4px;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			font-size: 20px;
			line-height: 28px;
			font-weight: bold;
			color: #fff;

			&:focus,
			&:hover,
			&:disabled {
				color: #fff;
			}

			&.disabled {
				opacity: 0.5;
				color: #fff;
			}
		}
	}
	&__footer {
		padding-top: 10px;
		padding-bottom: 12px;
		margin-top: 32px;
		border-top: 1px solid #c8ced3;
	}
	&__more {
		display: block;
		margin-bottom: 0;
		text-align: right;

		&-input {
			border: 0;
			clip: rect(0 0 0 0);
			-webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
			clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
			white-space: nowrap;
		}
		&-display {
			position: relative;
			display: inline-block;
			padding: 8px 15px;
			font-size: 13px;
			line-height: 21px;
			font-weight: bold;
			text-align: left;
			color: #fff;
			background-color: #20a8d8;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 4px;
			cursor: pointer;

			&-icon {
				margin-left: 10px;
			}
		}
	}
}

.tender-route-additional-info {
	margin-left: -30px;
	margin-right: -30px;
	padding: 20px 30px;
	background-color: #fff;

	&__main-title {
		margin-top: 40px;
		margin-bottom: 20px;
		font-size: 28px;
		line-height: 34px;
		font-weight: normal;

		&:first-child {
			margin-top: 0;
		}
	}
	&__title {
		margin-bottom: 12px;
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;

		&-note {
			font-size: 14px;
			font-weight: normal;
			font-style: italic;
			color: #768192;
		}
	}
	&__desc {
		margin-top: 12px;
		margin-bottom: 12px;
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;
		color: #768192;
	}
	&__destinations {
		list-style: inside none;
		padding-left: 0;

		&-item {
			position: relative;
			padding-bottom: 40px;
			padding-left: 20px;
			margin-bottom: 6px;

			&:before {
				position: absolute;
				bottom: 0;
				left: 3px;
				display: block;
				width: 5px;
				height: calc(100% - 24px);
				background-color: #20a8d8;
				content: "";
			}
			&:last-child {
				padding-bottom: 0;

				&:before {
					display: none;
				}
			}

			&-icon {
				position: absolute;
				top: 2px;
				left: 0;
			}
		}
	}
	&__list {
		list-style-position: inside;
		padding-left: 0;

		&-item {
			margin-top: 12px;
			margin-bottom: 12px;
			font-size: 16px;
			line-height: 21px;
			font-weight: bold;
			color: #768192;

			&::marker {
				margin-right: 10px;
			}
		}
	}
}
/* Update tenders styles */
</style>
